<template>
  <div id="app">
    <router-view></router-view>
    <!--    回滚-->
    <div class="hg">
      <el-backtop></el-backtop>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},

};
</script>

<style>

#app {
  width: 100%;
  margin: 0 auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  position: relative;
  min-width: 1440px;
  max-width: 3220px;
}
a, address, b, big, blockquote, body, center, cite, code, dd, del, div, dl, dt, em, fieldset, font, form, h1, h2, h3, h4, h5, h6, html, i, iframe, img, ins, label, legend, li, ol, p, pre, small, span, strong, u, ul, var{
  margin: 0;
  padding: 0;
}
body{
  overflow-anchor: none;
  padding: 0;
  margin: 0;
  /*font: 12px/1.5 tahoma,'\5FAE\8F6F\96C5\9ED1',sans-serif;*/
}
ul li{
  list-style: none;
}
span{
  cursor: pointer;
}
p{
  cursor: pointer;
}
/*底层*/
.ground-floor {
  width: 100%;
  height: 389px;
  background-color: #262C50;
}

.ground-floor .fbox {
  display: inline-block;
  width: 100%;
  margin-top: 40px;
}

.ground-floor .fbox1 {
  display: flex;
  flex-direction: column;
  width: 1180px;
  margin: 0 auto;
  /*background-color: #FFFFFF;*/
}

.ground-floor .fbox1 .fbox2 {
  display: flex;
  justify-content: space-between;
  /*width: 1440px;*/
  height: 278px;
}
.ground-floor .fbox1 .cut-line1{
  width: 1180px;
  height: 1px;
  background: rgba(255, 255, 255, 0.38);
}

.ground-floor .fbox1 .fbox2 .lianxi {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 100%;
}

.ground-floor .fbox1 .fbox2 .lianxi .fico {
  display: inline-block;
  width: 134px;
  height: 40px;
  font-size: 20px;
}

.ground-floor .fbox1 .fbox2 .lianxi .fico .ficon {
  display: inline-block;
  width: 46px;
  height: 40px;
}

.ground-floor .fbox1 .fbox2 .lianxi .fico .fdesignation {
  float: right;
  line-height: 40px;
  color: #FFFFFF;
}

.ground-floor .fbox1 .fbox2 .lianxi .contway {
  display: flex;
  flex-direction: column;
  width: 263px;
  height: 80px;
  margin-top: 31px;
}

.ground-floor .fbox1 .fbox2 .lianxi .contway .special {
  color: #FFFFFF;
  margin-top: 12px;
  font-size: 12px;
}

.ground-floor .fbox1 .fbox2 .lianxi .QR-code {
  display: inline-block;
  width: 293px;
  height: 122px;
  margin-top: 21px;
  /*background-color: #262C50;*/
}

.ground-floor .fbox1 .fbox2 .lianxi .QR-code .QR-code1 {
  width: 122px;
  height: 122px;
  display: block;
  margin: 0 auto;
  background-color: #979797;
}

.ground-floor .fbox1 .fbox2 .cut-line {
  width: 1px;
  height: 226px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.38);
}

.ground-floor .fbox1 .fbox2 .explain {
  /*width: 1146px;*/
  height: 100%;
  /*background-color: black;*/
}

.ground-floor .fbox1 .fbox2 .explain .explain1 {
  display: flex;
  /*width: 770px;*/
  height: 100%;
  margin-left: 63px;
  /*background-color: red;*/
}

.ground-floor .fbox1 .fbox2 .explain .explain1 .rice {
  display: flex;
}

.ground-floor .fbox1 .fbox2 .explain .explain1 .rice1 span {
  color: #FFFFFF;
  font-size: 16px;
  margin-bottom: 25px;
}

.ground-floor .fbox1 .fbox2 .explain .explain1 .rice .rice1 {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 100%;
}

.ground-floor .fbox1 .fbox2 .explain .explain1 .rice .rice1 ul li {
  margin-bottom: 17px;
}

.ground-floor .fbox1 .fbox2 .explain .explain1 .rice .rice1 ul li span {
  font-size: 12px;
}
.ground-floor .fbox1 .safety{
  flex: 1;
  display: flex;
  justify-content: space-between;
}
.ground-floor .fbox1 .safety span{
  line-height: 70px;
  color: #FFFFFF;
  font-size: 14px;
}
.ground-floor .fbox1 .safety a{
  line-height: 70px;
  color: #FFFFFF;
  font-size: 14px;
}
.zss{
  width: 1180px;
  height: 100%;
  margin: 0 auto;
}
/*导航*/
.dh{
  width: 100%;
  height: 60px;
  position: fixed;
  background-color: white;
  z-index: 1999;
  border-bottom: 1px solid #BCBFD2;
  max-width: 3220px;
  min-width: 1440px;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1180px;
  height: 60px;
  margin: 0 auto;
  /*background: black;*/
}
.ico {
  display: inline-block;
  width: 134px;
  height: 40px;
  /*margin-left: 130px;*/
  /*margin-top: 8px;*/
  /*background: white;*/
  font-size: 20px;
}
.ico .icon {
  display: inline-block;
  width: 46px;
  height: 40px;
}
.ico .designation {
  float: right;
  line-height: 40px;
  color: black;
}
.selectivity {
  display: inline-block;
  width: 862px;
  height: 60px;
  max-width: 1358px;
  /*background: white;*/
  float: right;
  /*margin-right: 246px;*/
}
.selectivity .alternative {
  display: flex;
  justify-content: space-between;
  /*width: 780px;*/
  height: 60px;
  /*margin-right: 64px;*/
  color: black;
}
.selectivity .alternative .xzxz{
  display: flex;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
}
.selectivity .alternative .xzxz img{
  display: block;
  width: 18px;
  height: 18px;
  color: black;
}
.selectivity .alternative .xzxz1{
  position: relative;
  height: 60px;
}
.selectivity .alternative .xzxz1 .nav{
  display: flex;
}
.selectivity .alternative .xzx1 .nav1{
  width: 780px;
  position: absolute;
  box-shadow: inset 0px 3px 16px 0px #C7D6F6;
  background-color: white;
  box-sizing: border-box;
  transition: all .5s;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.selectivity .alternative .xzx1:hover .nav1{
  height: 258px;
  opacity: 1;
}
.selectivity .alternative .xzx2 .nav2{
  width: 780px;
  position: absolute;
  left: -143px;
  box-shadow: inset 0px 3px 16px 0px #C7D6F6;
  background-color: white;
  box-sizing: border-box;
  transition: all .5s;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.selectivity .alternative .xzx2:hover .nav2{
  height: 258px;
  opacity: 1;
}
.selectivity .alternative .xzx3 .nav3{
  width: 780px;
  position: absolute;
  left: -286px;
  box-shadow: inset 0px 3px 16px 0px #C7D6F6;
  background-color: white;
  box-sizing: border-box;
  transition: all .5s;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.selectivity .alternative .xzx3:hover .nav3{
  height: 258px;
  opacity: 1;
}
.selectivity .alternative .xzxz1:hover .xzxz{
  color: #124BFF;
}
.selectivity .alternative .xzxz1:hover .xzl{
  width: 64px;
  height: 3px;
  background: #124BFF;
  position: absolute;
  bottom: 0px;
}
.selectivity .alternative .xzxz1 .nav .lin-xx{
  display: flex;
  flex-direction: column;
  width: 142px;
  margin-top: 42px;
}
.selectivity .alternative .xzxz1 .nav .lin-xx .lin-cut{
  height: 32px;
  display: block;
  width: 2px;
  background-color: #BCBFD2;
}
.selectivity .alternative .xzxz1 .nav .hz:hover p{
  color: #124BFF;
}
.selectivity .alternative .xzxz1 .nav .hz:hover .lin-cut{
  background-color: #124BFF;
}
.selectivity .alternative .xzxz1 .nav .lin-xx .hz{
  display: flex;
  height: 32px;
}
.selectivity .alternative .xzxz1 .nav .lin-xx .hz p{
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  margin-left: 32px;
}
.selectivity .alternative .xzxz1 .nav .pt{
  margin: 42px 32px;
}
.selectivity .alternative .xzxz1 .nav .navxx{
  display: flex;
  width: 270px;
  height: 138px;
  flex-direction: column;
  justify-content: space-between;
  margin: 42px 35px 66px 20px;
}
.selectivity .alternative .xzxz1 .nav .navxx span:nth-child(1){
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #202B4E;
  line-height: 20px;
}
.selectivity .alternative .xzxz1 .nav .navxx span:nth-child(2){
  width: 270px;
  height: 60px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666F8F;
  line-height: 20px;
}
.selectivity .alternative .xzxz1 .nav .navxx span:nth-child(3){
  height: 17px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0D50E1;
  line-height: 17px;
}
.selectivity .alternative .xzxz1 .nav .navxx span:nth-child(3) .r-jt1{
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 1px solid #124BFF;
  border-left-width: 0;
  border-bottom-width: 0;
  transition: all .5s;
  transform: matrix(0.71,0.71,-0.71,0.71,0,0);
}
.selectivity .alternative .xzxz1 .nav .navxx span:nth-child(3):hover .r-jt1{
  margin-left: 20px;
}
</style>
