import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'index',
    component: () => import('../views/Home.vue')
}, {
    path: '/Partner',
    name: 'Partner',
    component: () => import('../views/Partner.vue')
}, {
    path: '/information',
    name: 'information',
    component: () => import('../views/ information.vue')
}, {
    path: '/Clien',
    name: 'Clien',
    component: () => import('../views/Clien.vue')
}, {
    path: '/Solution',
    name: 'Solution',
    component: () => import('../views/Solution.vue')
}, {
    path: '/Autonomous',
    name: 'Autonomous',
    component: () => import('../views/Autonomous.vue')
}, {
    path: '/FinAnce',
    name: 'FinAnce',
    component: () => import('../views/InduStry/FinAnce.vue')
}, {
    path: '/MaKe',
    name: 'MaKe',
    component: () => import('../views/InduStry/MaKe.vue')
}, {
    path: '/ReTail',
    name: 'ReTail',
    component: () => import('../views/InduStry/ReTail.vue')
}, {
    path: '/AutoMobile',
    name: 'AutoMobile',
    component: () => import('../views/InduStry/AutoMobile.vue')
}, {
    path: '/CustoMization',
    name: 'CustoMization',
    component: () => import('../views/ScoPe/CustoMization.vue')
}, {
    path: '/ProDuct',
    name: 'ProDuct',
    component: () => import('../views/ScoPe/ProDuct.vue')
}, {
    path: '/PlatForm',
    name: 'PlatForm',
    component: () => import('../views/ScoPe/PlatForm.vue')
}, {
    path: '/DigiTization',
    name: 'DigiTization',
    component: () => import('../views/ScoPe/DigiTization.vue')
}, {
    path: '/OperAtion',
    name: 'OperAtion',
    component: () => import('../views/ScoPe/OperAtion.vue')
}, {
    path: '/EpiBoly',
    name: 'EpiBoly',
    component: () => import('../views/ScoPe/EpiBoly.vue')
}, {
    path: '/WeaLth',
    name: 'WeaLth',
    component: () => import('../views/CliEnt/WeaLth.vue')
}, {
    path: '/EnterPrise',
    name: 'EnterPrise',
    component: () => import('../views/CliEnt/EnterPrise.vue')
}, {
    path: '/SmaRt',
    name: 'SmaRt',
    component: () => import('../views/CliEnt/SmaRt.vue')
}, {
    path: '/Fund',
    name: 'Fund',
    component: () => import('../views/CliEnt/Fund.vue')
}, {
    path: '/MainTenance',
    name: 'MainTenance',
    component: () => import('../views/CliEnt/MainTenance.vue')
}, {
    path: '/ectocyst',
    name: 'ectocyst',
    component: () => import('../views/CliEnt/ectocyst.vue')
}, {
    path: '/IndepenDent',
    name: 'IndepenDent',
    component: () => import('../views/IndepenDent.vue')
}, {
    path: '/adviser',
    name: 'adviser',
    component: () => import('../views/CliEnt/adviser.vue')
}, {
    path: '/database',
    name: 'database',
    component: () => import('../views/CliEnt/database.vue')
}, {
    path: '/marketing',
    name: 'marketing',
    component: () => import('../views/CliEnt/marketing.vue')
}, {
    path: '/union',
    name: 'union',
    component: () => import('../views/CliEnt/union.vue')
}, {
    path: '/scope',
    name: 'scope',
    component: () => import('../views/scope.vue')
}, {
    path: '/Faucet',
    name: 'Faucet',
    component: () => import('../views/CliEnt/Faucet.vue')
}, {
    path: '/IPO',
    name: 'IPO',
    component: () => import('../views/CliEnt/IPO.vue')
}
]
const router = new VueRouter({
    mode:'history',
    routes
})
export default router